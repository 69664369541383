body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.acetrace-counter {
  display: flex;
  flex-direction: row;
  font-family: Quicksand, sans-serif;
  align-items: center;
  justify-content: flex-end;
  height: 40px;
  font-weight: bold;
  font-size: 26px;
  width: 360px;
  margin: auto;
}

.acetrace-counter-number {
  margin-right: 0.5em;
  color: white;
  text-align: center;
  transition: font-size 0.2s ease-in-out, color 0.2s ease-in-out;
}

.acetrace-counter-number-flashing {
  color: #FFCE5A;
  font-size: 32px;
}

.acetrace-counter-text {
  color: white;
  font-size: 24px;
}